import React from 'react';
import dataSocial from './data.json';
import Image from '../../image';
import Swiper from 'react-id-swiper';
import SwiperCore, { Autoplay, EffectFade, Pagination } from 'swiper';
import 'swiper/components/pagination/pagination.min.css';
import { Helmet } from 'react-helmet-async';

SwiperCore.use([Autoplay, EffectFade, Pagination]);

const sliderParams = {
  slidesPerView: 0,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  fadeEffect: { crossFade: true },
  speed: 500,
  autoplayDisableOnInteraction: true,

  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
};

const SocialProofQuoteCardST = ({
  lang,
  dataTheme,
  extraMargin,
  storybook,
}) => {
  const language = typeof lang === 'undefined' ? 'se_sv' : lang;
  const content = dataSocial[language];

  return (
    <section data-theme={dataTheme} className="SocialProofQuoteCardST">
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/swiper/swiper-bundle.css"
        />
        {' '}
        <script src="https://unpkg.com/swiper/swiper-bundle.js" />
      </Helmet>
      <div
        className={`container container--lg margin-bottom--md margin-bottom--sm ${extraMargin}`}
      >
        <div className="stats_bar">
          {content
            && content.map((main) => (
              <div
                key={main}
                className="float grid flex flex--center-y cardColor"
              >
                <div className="col col--lg-5 display--lg swiper">
                  <div
                    // style={{ overflow: "hidden" }}
                    className="margin-right--lg"
                  >
                    <Swiper
                      key={main}
                      {...sliderParams}
                      // style={{ width: "100%!important" }}
                    >
                      {main.quotes
                        && main.quotes.map((quote) => (
                          <div key={quote.pt}>
                            <div
                              className="swiper-slide"
                              // style={{ width: "100%!important" }}
                            >
                              <p>
                                “
                                {quote.quote}
                                ”
                                <span className="opacity--6 margin-left--xxs">
                                  -
                                  {' '}
                                  {quote.pt}
                                </span>
                                <br />
                              </p>
                            </div>
                          </div>
                        ))}
                    </Swiper>
                  </div>
                </div>
                <div className="col col--12 col--lg-7 dataSocial">
                  <div className={`flex ${main.data.length === 2 ? 'flex--space-around' : 'flex--space-between'} flex--center-y`}>
                    {main.data
                      && main.data.map((data) => (
                        <div
                          key={data.title}
                          className="text--center"
                          // style={{ flexShrink: "0" }}
                        >
                          <p
                            className="text--xl text--title text--700 lineHeight"
                            // style={{ lineHeight: "1em" }}
                          >
                            {data.data}
                          </p>

                          <p className="text--sm text--title opacity--8 margin-bottom--xxs">
                            {data.title}
                          </p>
                          {storybook ? (
                            <img src={data.storybookIcon} alt="sb pic" />
                          ) : (
                            <Image
                              classname="social-icons"
                              filename={data.icon}
                              alt={data.icon}
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default SocialProofQuoteCardST;
