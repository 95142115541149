import React from 'react';
import { string, bool } from 'prop-types';
import { LANG_SE_SV } from '@/src/common/consts/lang';
import { THEME_WHITE } from '@/src/common/consts/theme';

import ImgSe from '@assets/today-neck-se.jpg';
import ImgEn from '@assets/today-knee-us-1.jpg';
import Image from '../../image';
import contentData from './data.json';

const Treatments = ({ lang, downloadhref, padBottom }) => {
  const content = contentData[lang];

  if (!content) {
    return null;
  }

  const imgSrc = lang === 'se_sv' ? ImgSe : ImgEn;

  return (
    <section data-theme={THEME_WHITE} className="Treatments icon-cards-image">
      <div
        className={`container margin-top--lg ${padBottom ? 'margin-bottom--xl' : ''}`}
      >
        <div className="grid grid-gap--lg">
          <div className="col col--12 col--sm-5 display--sm">
            <div className="icon-cards-image__image-wrapper">
              <img src={imgSrc} alt="" />
            </div>
          </div>

          <div className="col col--12 col--sm-7 icon-cards-image__text-col">
            <div className="icon-cards-image__text">
              <div className="margin-bottom--sm text--center text--component text--left-sm">
                <h2>{content.title}</h2>
                <p>{content.text}</p>
              </div>

              <div className="icon-cards-image__icon-cards">
                {content.cards && content.cards.map((card) => (
                  <a href={downloadhref} className="icon-cards-image__card">
                    <div className="icon-cards-image__card-image-wrapper">
                      <Image
                        filename={card.icon}
                        alt="alt"
                        classname="widthIcons"
                      />
                    </div>

                    <h4 className="icon-cards-image__card-title text--center">
                      {card.title}
                    </h4>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  );
};

Treatments.defaultProps = {
  downloadhref: '#',
  lang: LANG_SE_SV,
  padBottom: true,
};

Treatments.propTypes = {
  downloadhref: string,
  lang: string,
  padBottom: bool,
};

export default Treatments;
